#event-info-section {
	grid-template-areas: ". time place ." ". organizer place ." ". performer . ." "tags tags tags tags";
	grid-template-columns: 1fr minmax(320px, 380px) minmax(350px, 500px) 1fr;
	gap: 8px;
	line-height: 1.65;
}

.event-name {
	font-size: 2.2em;
	color: var(--heading-color);
	margin-top: 0;
}

.event-section-heading {
	margin: 0 0 0.4em 0;
	font-size: 1.2em;
	color: var(--heading-color);
}

.event-tags {
	grid-area: tags;
}

.event-tag-list {
	gap: 0.8em;
	overflow-x: auto;
}

#organizer-card .same-as-links {
	padding: 0.4rem;
}

#event-info-section #performer-card .performer-info {
	text-decoration: none;
}

.event-card {
	background-color: var(--bs-gray-100);

	& .event-location {
		margin-block: 0.6em;
		height: 4.8em;
	}

	@media (prefers-color-scheme: dark) {
		& {
			background-color: var(--bs-gray-900);
		}
	}
}

#place-card {
	grid-area: place;
}

#time-card {
	grid-area: time;
}

#organizer-card {
	grid-area: organizer;
}

#organizer-info > a {
	display: block;
}

#performer-card {
	grid-area: performer;
}

#performer-card .performer-info {
	gap: 1.8rem;
}

#performer-card .performer-image {
	width: 96px;
	height: 96px;
	object-position: center;
	object-fit: contain;
}

#place-card .leaflet-map {
	width: 100%;
	min-height: 250px;
	border: none;
}

.event-page-offers.flex, .event-details.flex.row {
	justify-content: space-around;
	gap: 1.3rem;
	margin-top: 1.3rem;
}

.event-page-header figure, .event-card figure {
	margin-inline: 0;
}

.event-page-header .event-img {
	width: 100%;
	display: block;
}

.event-page-header .event-img, .event-card-img {
	object-fit: cover;
	object-position: center;
	border-radius: 6px;
}

.event-location-name {
	color: var(--heading-color);
}

.layout-event-page .share-btns hr {
	display: none;
}

@media (max-width: 750px) {
	#event-info-section {
		grid-template-areas: "time" "place" "organizer" "performer";
		grid-template-columns: auto;
	}
}

@media (min-width: 801px) {
	.event-card .event-title {
		height: 2.7em;
		margin-block: 0.3em;
	}

	.event-card-img {
		aspect-ratio: 4 / 3;
		width: 100%;
	}
}


.grid .event-sponsors {
	--sponsor-size: 96px;
	grid-template-rows: repeat(auto-fill, var(--sponsor-size));
	grid-template-columns: repeat(auto-fill, minmax(var(--sponsor-size), calc(1.5 * var(--sponsor-size))));
	gap: 0.8em;

	& .event-sponsor {
		margin-inline: auto;
		display: inline-block;
	}

	& .sponsor-link {
		text-decoration: none;
		display: inline-block;
	}

	& .event-sponsor-logo {
		height: var(--sponsor-size);
		width: auto;
	}
}
