@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/palette/bootstrap.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/theme/default/index.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/theme/properties.css");

@media all {
	:root[theme], :root:not([theme]) {
		font-size: clamp(18px, 1.3vmax, 22px);
		--header-height: 0;
		--nav-height: 4rem;


		/*================= Set color vars here =================*/
		--accent-color-dark: var(--bs-primary);
		--accent-color-light: var(--bs-primary);
		--button-primary-background: var(--bs-primary);

		/*==================== Set fonts here ====================*/
		--main-font: system-ui, 'Roboto';
		--title-font: 'Alice', cursive;
		--header-font: 'Alice', cursive;
		--article-font: 'Ubuntu';
		--article-header-font: var(--header-font);
		--sidebar-max-width: 30vw;
		--map-width: 25vw;
		--card-bg: var(--bs-gray-100);
		--card-border-color: #cacaca;
		--default-color-dark: var(--bs-gray-100);
		--primary-color-light: var(--bs-gray-200);
		--primary-color-dark: var(--bs-gray-800);
		--heading-color-light: var(--bs-blue-700);
		--heading-color-dark: var(--bs-blue-200);
		--heading-color: var(--heading-color-light);

		--scrollbar-width: thin;
		--card-border: 1px solid var(--card-border-color);

		@media (prefers-color-scheme: dark) {
			& {
				--card-bg: var(--bs-gray-900);
				--heading-color: var(--heading-color-dark);
				--card-border-color: #5b5b5b;
			}
		}
	}
}

@media (max-width: 800px) {
	:root {
		--map-width: 100%;
		--scrollbar-width: auto;
	}
}

