#sidebar {
	grid-area: sidebar;
	--ad-block-width: 30vw;
	--ad-block-stack-width: var(--ad-block-width);
	--ad-block-text-width: var(--ad-block-width);
	--ad-block-image-width: var(--ad-block-width);
	--map-width: 100%;
}

#sidebar > .card {
	--card-radius: 8px;
}

#sidebar > *::part(container) {
	background-color: var(--card-bg);
	border-color: var(--card-border-color);
	border-radius: 8px;
}

#sidebar weather-current {
	margin: 0;
	max-width: 100%;
	width: 100%;
	border: none;
}

#sidebar .leaflet-map {
	width: var(--map-width, 100%);
	height: var(--map-height, 30vh);
	border: none;
}

@media (max-width: 800px) {
	#sidebar {
		--ad-block-width: 95vw;
	}
}

#sidebar .sidebar-section {
	margin-bottom: 0.6em;
}

details.accordion[open] > :not(summary) {
	animation: slideInDown 200ms ease-in;
}
