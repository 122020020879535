@charset "UTF-8";
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/viewport.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/rem.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/element.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/class-rules.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/animations.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/cdn-fonts.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/scrollbar.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/accordion.css");
@import url("https://unpkg.com/@shgysk8zer0/core-css@2.5.4/forms.css");
@import url("./layout.css");
@import url("./vars.css");

#events-calendar details.event-details summary.btn {
	display: block;
	width: 100%;
}

.event-details > .card {
	max-width: 100%;
	width: 650px;
}

button.btn.event-offer-btn {
	cursor: default;
}

.btn.btn-big {
	font-size: 1.3em;
	padding: 0.4em 1.3em;
}

button:disabled.btn {
	background: var(--button-disabled-background);
}

dialog {
	border: none;
}

summary[role="button"] {
	display: list-item;
}

.share-btns share-to-button {
	margin: 0.6em 0.4em 0 0;
}

#events-calendar {
	grid-template-columns: repeat(auto-fill, minmax(320px, 380px));
	grid-template-rows: masonry;
	grid-gap: 0.8em;
	justify-content: center;
}

@media (max-width: 600px) {
	.share-btns share-to-button::part(text),
	.share-btns share-to-button::part(spacer) {
		display: none;
	}

	.share-btns share-to-button::part(container) {
		min-width: 4em;
		min-height: 4em;
		padding: 0.8em;
		justify-content: center;
		align-items: center;
	}
}

.full-width {
	width: 100%;
}

.info-text {
	color: #6c6565;
}

.stepped-form > .form-step:not(.step-active) {
	display: none;
}

a.btn {
	text-decoration: none;
}
