#header {
	margin-bottom: 1.2rem;
}

#app-menu, #install-btn {
	background-color: transparent;
	border: none;
	color: inherit;
	min-width: 47px;
}

#install-btn > svg {
	margin-bottom: 10px;
}

#header .site-short-name {
	display: none;
}

@media (max-width: 800px) {
	#header .site-short-name {
		display: inline;
	}

	#header .site-name {
		display: none;
	}
}

#header a.float-left[title="Home"] {
	margin-left: 0.4rem;
}

#header svg {
	vertical-align: middle;
	height: 1em;
	width: 1em;
}
