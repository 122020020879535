@import url("./header.css");
@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");
@import url("./event-info.css");

/* @import url("https://cdn.kernvalley.us/css/core-css/layout/index.css"); */

body {
	display: grid;
	grid-template-areas: "nav nav nav nav" ". main . sidebar" "footer footer footer footer";
	grid-template-rows: var(--nav-height, 4rem) 1fr auto;
	grid-template-columns: 1em 1fr 1em 400px;
	gap: 8px;
}

#organizer-info {
	gap: 8px;
}

#nav {
	grid-area: nav;
}

#main {
	grid-area: main;
}

#sidebar {
	grid-area: sidebar;
}

#footer {
	grid-area: footer;
}

#site-tags {
	line-height: 1.8;
	font-size: 1.2em;
}

@media (max-width: 980px) {
	:root {
		--map-height: calc(80vh - var(--nav-height, 4rem));
	}

	body {
		grid-template-areas: "nav" "main" "sidebar" "footer";
		grid-template-columns: 100%;
		grid-template-rows: var(--nav-height, 4rem) 1fr auto auto;
		gap: 8px 0;
	}
}

button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}

	#main, #sidebar {
		width: 95%;
		margin-inline: auto;
	}
}
