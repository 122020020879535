#nav {
	font-size: 1.2em;
	gap: 18px;
	padding-inline: 0.6em;

	& .nav-btn {
		background-color: transparent;
		color: inherit;
		border: none;
	}
}

#install-btn > svg {
	margin-bottom: 10px;
}
